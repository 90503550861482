<template>
    <appHeader :activeMenu="this.activeMenu"/>
    <slot>

    </slot>
    <scrollTop :scY="scY"/>
    <fixedBar :scY="scY"/>
    <appFooter/>
</template>
<script>
import appHeader from "@/components/share/user/appHeader.vue";
import scrollTop from "@/components/share/scrollTop.vue";
import fixedBar from "@/components/share/user/fixedBar.vue";
import appFooter from "@/components/share/user/appFooter.vue";

export default {
    props: ['activeMenu'],
    components: {
        appHeader,
        scrollTop,
        fixedBar,
        appFooter,
    },
    data() {
        return {
            rate: "rate0",
            scTimer: 0,
            scY: 0,
        };
    },
    created() {
        window.scrollTo(0, 0);
        if (this.$store.getters._getLang !== this.$route.params.lang) {
            this.$store.commit("setLang", this.$route.params.lang)
            location.reload();
        }
        if (!this.$store.getters._getApiCheck) {
            this.$store.commit("setApiCheck", true)
            location.reload()
        }

        this.$appAxios.post("user/userBalance", {token: this.$store.getters._getToken}, {
            headers: {
                "Accept-Language": "tr",
                "Content-Type": "multipart/form-data"
            }
        })
            .then(response => {
                this.$store.commit("setBalance", response?.data?.data)
            })
        this.$appAxios.post("/user/checkToken",
            {
                token: this.$store.getters._getToken,
            })
            .catch(res => {
                if (!res.success) {
                    this.$store.commit("logoutUser")
                    this.$router.push({name: "Login"})
                }
            })

    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll: function () {
            if (this.scTimer) return;
            this.scTimer = setTimeout(() => {
                this.scY = window.scrollY
                clearTimeout(this.scTimer);
                this.scTimer = 0;
            }, 100);
        }
    }
}
</script>
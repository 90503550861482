<template>
    <div class="scroll-top" :class="{active:scY>150}" @click="toTop()">
        <i class="fa-regular fa-arrow-left"></i>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    props: ['scY'],
    methods: {
        toTop: function () {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
    },
}
</script>
<style lang="scss" scoped>
.scroll-top {
  background: transparent;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  color: #1E48FF;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 32px;
  transition: 400ms all;
  bottom: -300px;
  cursor: pointer;
  border: 1px solid #1E48FF;

  i {
    color: #1E48FF;
    font-size: 28px;
  }

  &.active {
    bottom: 57px;
    transform: rotate(90deg);
  }
}

@media only screen and (max-width: 1450px) {
  .scroll-top {
    width: 35px;
    height: 35px;

    i {
      font-size: 20px;
    }
  }
}

</style>
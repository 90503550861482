<template>
  <div class="icon-button" :class="{active:isActive}" v-click-outside-element="closeMenu">
    <i class="fa-regular fa-user" @click="isActive=!isActive"></i>
    <div class="accountMenu">
      <div @click="passwordModalCheck = true"><span>{{ $t("change-password") }}</span></div>
      <div @click="choiceModalCheck = true"><span>{{ $t("customize-wellow") }}</span></div>
        <router-link :to="{name:'UserPurchased'}" class="entrance-right-bottom">
            <span>{{ this.$t("purchased") }}</span>
        </router-link>
      <router-link :to="{name:'UserFAQ'}">
        <span>{{ $t("support-center") }}</span>
      </router-link>
      <div @click="logout"><span>{{ $t("logout") }}</span></div>
    </div>
  </div>
  <div class="wellow-modal-shadow" v-if="choiceModalCheck" id="new-account-flow">
    <div class="wellow-modal">
      <i class="fa-thin fa-xmark close-modal" @click="closeModal"></i>
      <div class="wellow-modal-inner">
        <div class="wellow-modal-head">
          <h2>{{ this.$t("change-your-selection") }}</h2>
        </div>
        <div class="wellow-modal-body">
          <p>{{ this.$t("choose-five-cat") }}</p>
          <div class="editor-choices">
            <input v-for="(choice,index) in choices" type="submit" :key="choice" :class="{active:choice.activity }"
                   :value="choice.name" @click="addCat(index)">
          </div>
          <p>{{ this.$t("we-offer-you-experience-products") }}</p>
        </div>
        <div class="wellow-modal-foot">
          <div class="buttons">
            <button class="prev" @click="closeModal">{{ $t('cancel') }}</button>
            <button class="next" @click="removeActive">{{ $t('save') }}</button>
          </div>
        </div>

      </div>
    </div>
  </div>
  <passwordModal v-if="passwordModalCheck" :checkClose="true" @closeModal="closeModal"/>
  <LifeWheelModal v-if="wheelModalCheck" @closeModal="closeModal"/>
</template>
<script>
import passwordModal from "@/components/modals/passwordModal.vue";
import LifeWheelModal from "@/components/modals/lifeWheelModal.vue";

export default {
  data() {
    return {
      isActive: false,
      passwordModalCheck: false,
      choiceModalCheck: false,
      wheelModalCheck: false,
      choices: []
    };
  },
  components: {
    LifeWheelModal,
    passwordModal,
  },
  async created() {
    this.choices = await this.$CategoryAPI.getCategories()
  },
  methods: {
    addCat(i) {
      let count = 0;
      this.choices.forEach(c => {
        if (c.activity) {
          count++
        }
      })

      if (!this.choices[i].activity || (this.choices[i].activity && count > 5)) {
        this.$appAxios.post("user/addRemoveCategories", {
          token: this.$store.getters._getToken,
          category_id: this.choices[i].id
        })
            .then(response => {
              if (response.status === 200) {
                this.choices[i].activity = !this.choices[i].activity
              }
            })
      }


    },
    closeModal() {
      this.passwordModalCheck = false
      location.reload()
    },
    logout() {
      this.$store.commit("logoutUser");
      this.$router.push({name: "Login"})
    },
    removeActive() {
      this.passwordModalCheck = false
      this.wheelModalCheck = true
    },
    closeMenu() {
      this.isActive = false
    }
  }

}
</script>
<style lang="scss" scoped>
.icon-button {
  position: relative;

  i {
    color: black;
    font-size: 20px;
    width: 36px;
    height: 36px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: 400ms all;
    cursor: pointer;

    &:hover {
      font-weight: 800;
      border: 1px solid #CED4DA;
      box-shadow: 0 2px 10px #6C757D3F;
      color: #FEC629;;
    }
  }

  .accountMenu {
    position: absolute;
    opacity: 0;
    top: 50px;
    width: max-content;
    z-index: 88;
    background: white;
    visibility: hidden;
    transition: 400ms all;
    box-shadow: 0 2px 10px rgba(108, 117, 125, 0.25);
    border-radius: 10px;
    right: 0;
    overflow: hidden;

    div,
    a {
      padding: 15px 32px;
      border-bottom: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition: 400ms all;
      cursor: pointer;

      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #3A3A3A;
      }

      &:hover {
        background: #FEF5CC;
      }

      &:last-child {
        border: none;
      }
    }

  }

  &.active {
    .accountMenu {
      opacity: 1;
      visibility: visible;
    }

    i {
      font-size: 20px;
      width: 36px;
      font-weight: 400;
      border: 1px solid #CED4DA;
      box-shadow: 0 2px 10px #6C757D3F;
      color: #FEC629;
    }
  }
}
a{
  &.icon-button{
    &:hover {
      i {
        font-weight: 800;
        border: 1px solid #CED4DA;
        box-shadow: 0 2px 10px #6C757D3F;
        color: #FEC629;;
      }
    }
  }
}
@media only screen and ( max-width: 1450px) {
  .icon-button {
    i {
      font-size: 20px;
      width: 25px;
    }

    .language {
      font-size: 18px;
      line-height: 27px;
    }

    .languageMenu {
      top: 45px;
      div {
        padding: 10px 15px;
      }
    }

    .accountMenu {
      top: 45px;

      div {
        padding: 10px 15px;
        span {
          font-size: 14px;
          line-height: 20px;
        }
      }

      a {
        padding: 10px 15px;
        gap: 10px;
        span {
          font-size: 14px;
          line-height: 20px;
        }

      }
    }

  }
}


</style>
<template>
  <div class="wellow-modal-shadow" v-if="passwordModalCheck" id="change-password" style="z-index: 10001;">
    <div class="wellow-modal small">
      <i class="fa-thin fa-xmark close-modal" @click="closeModals" v-if="checkClose"></i>
      <div class="wellow-modal-inner">
        <div class="wellow-modal-head">
          <h2>{{ $t("change-password") }}</h2>
        </div>
        <div class="wellow-modal-body">
          <div class="modal-inputs" v-if="!changedPassword">
            <div class="wellow-inp-group">
              <label for="currentPassword">{{ $t("enter-current-password") }}</label>
              <div>
                <input :type="old_password ? 'text' : 'password'" v-model="changeData.old_password"
                       id="currentPassword">
                <a @click="old_password=!old_password" :class="{active:old_password}">
                  <IconFy icon="akar-icons:eye-closed" color="#ced4da"/>
                  <i class="fa-solid fa-eye"></i>
                </a>
              </div>

            </div>
            <div class="wellow-inp-group">
              <label for="newPassword">{{ $t("enter-new-password") }}</label>
              <div>
                <input :type="password ? 'text' : 'password'" v-model="changeData.password" id="newPassword"
                       @input="check">
                <a @click="password=!password" :class="{active:password}">
                  <IconFy icon="akar-icons:eye-closed" color="#ced4da"/>
                  <i class="fa-solid fa-eye"></i>
                </a>
              </div>
              <div class="infos" v-if="this.changeData.password.length>0">
                <span :class="{danger:checkLength}">{{ $t("least-8-characters") }}</span>
                <span :class="{danger:checkNumber}">{{ $t("least-1-digit") }}</span>
                <span :class="{danger:checkChar}">{{ $t("least-1-symbol") }}</span>
              </div>
            </div>
            <div class="wellow-inp-group">
              <label for="againPassword">{{ $t("confirm-new-password") }}</label>
              <div>
                <input :type="new_password ? 'text' : 'password'" v-model="changeData.new_password" id="againPassword"
                       @input="check">
                <a @click="new_password=!new_password" :class="{active:new_password}">
                  <IconFy icon="akar-icons:eye-closed" color="#ced4da"/>
                  <i class="fa-solid fa-eye"></i>
                </a>
              </div>
              <div class="infos" v-if="this.changeData.new_password.length>0">
                <span :class="{danger:checkMatch}">{{ $t("new-password-incorrectly") }}</span>
              </div>
            </div>
          </div>
          <div class="passwordChanged" v-if="changedPassword">
            <img src="@/assets/images/media/changePassword.svg" alt="" class="step-img">
            <p>{{ $t("password-has-created.") }}</p>
          </div>
        </div>
        <div class="wellow-modal-foot">
          <div class="buttons" v-if="!changedPassword" :class="{rightAlign:!checkClose}">
            <button class="prev" @click="passwordModalCheck= false" v-if="checkClose">{{ $t("cancel") }}</button>
            <button class="save"
                    :class="{active:!checkLength&&!checkNumber&&!checkChar&&!checkMatch&&changeData.old_password}"
                    @click="post">{{ $t("save") }}
            </button>
          </div>
          <div class="buttons passwordChanged" v-else>
            <button @click="closeModals">{{ $t("continue") }}</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>

export default {

  data() {
    return {
      passwordModalCheck: true,
      changedPassword: false,
      old_password: false,
      password: false,
      new_password: false,
      checkLength: true,
      checkNumber: true,
      checkChar: true,
      checkMatch: true,
      changeData: {
        old_password: "",
        password: "",
        new_password: "",
      },
    };
  },
  props: ['checkClose'],
  methods: {
    check() {
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      this.checkLength = (this.changeData.password.length < 8) ? true : false;
      this.checkNumber = (/[0-9]/.test(this.changeData.password)) ? false : true;
      this.checkChar = (specialChars.test(this.changeData.password)) ? false : true;
      this.checkMatch = (this.changeData.password === this.changeData.new_password) ? false : true;
    },
    closeModals() {
      this.$emit("closeModal")
    },
    post() {
      this.changeData.token = this.$store.getters._getToken;
      this.$appAxios.post("user/change-password", this.changeData, {
        headers: {
          "Accept-Language": "tr",
          "Content-Type": "multipart/form-data"
        }
      }).then(response => {
        if (response.status === 200) {
          this.changedPassword = true;
        }
      })
    }
  }

}
</script>
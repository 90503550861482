import {appAxios} from '../../appAxios.js'
import store from '@/store'

const user = store.getters._getCurrentUser
let vendors
if(user?.roles[0]?.id === 4) {
    vendors = appAxios.post("vendor/list", {token: user.vue_token, type: 0}, {
        headers: {
            "Accept-Language": "tr",
            "Content-Type": "multipart/form-data"
        }
    })
        .then(response => {
            let vendor = response.data.data
            vendor.forEach(v=>{
                v.cardType = 3
            })
            return  vendor
        })

}
const Vendor = {
    async getVendors() {
        return vendors
    },
}

export default Vendor
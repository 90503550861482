import { appAxios } from '../../appAxios.js'
import store from '@/store'
import User from './user'
import UserPurchased from './userPurchased'
const user = store.getters._getCurrentUser

let experiences

if(user?.roles[0]?.id === 4) {
    experiences = appAxios.post("experiences/list", {
        token: user.vue_token,
        type: 0
    })
        .then(response => {
            let experiencesApi = response.data.data
            experiencesApi.forEach(ex => {
                ex.created_at = new Date(ex.created_at)
                ex.cardType = 0
                User.getUserFavoriteExperiences().then(response=>{
                    response.forEach(exs => {
                        if(exs && exs.id === ex.id ){
                            ex.isFavorite = 1
                        }
                    })
                })
                UserPurchased.getUserExperiences().then(response=>{
                    response.forEach(exs => {
                        if(exs.foreign_id === ex.id ){
                            ex.isBought = 1
                        }
                    })
                })
                ex.get_sessions.forEach(exs => {
                    exs.date = exs.date.split('-')
                    exs.date = new Date(exs.date[0], exs.date[1] - 1, exs.date[2])
                })
                let lifeTypes = []
                ex.get_experience_benefits_middle.forEach(ebm=>{
                    if(!(lifeTypes.lastIndexOf(ebm.get_life_type.slug_name) > -1)){
                        lifeTypes.push(ebm.get_life_type.slug_name)
                    }
                })
                ex.life_types = lifeTypes
            })
            return experiencesApi;
        })
}


const Experience ={
    async getExperiences(){
        return experiences
    },
    boughtExperience(id){
        experiences.then(ex=>{
            ex.forEach(e=>{
                if(id === e.id){
                    e.isBought = 1
                }
            })
        })
    },
    addFavoriteExperience(id){
        experiences.then(ex=>{
            ex.forEach(e=>{
                if(id === e.id){
                    e.isFavorite = 1
                }
            })
        })
    },
    removeFavoriteExperience(id){
        experiences.then(ex=>{
            ex.forEach(e=>{
                if(id === e.id){
                    e.isFavorite = 0
                }
            })
        })
    }
}

export default Experience

<template>
  <footer>
    <div class="footer-inner wellow-container">
      <div class="footer-top">
        <img src="@/assets/images/logo.svg" alt="">
        <div>
          <a><i class="fa-brands fa-linkedin-in"></i></a>
          <a><i class="fa-brands fa-instagram"></i></a>
          <a><i class="fa-brands fa-youtube"></i></a>
        </div>
      </div>
      <div class="footer-bottom">
        <div>
          <a>{{ $t("cookies") }}</a>
          <a>{{ $t("privacy-policy") }}</a>
          <a>{{ $t("faq") }}</a>
          <a>{{ $t("contact-us") }}</a>
        </div>
        <b>© 2022 Wellow</b>
      </div>
    </div>
  </footer>
</template>
<style lang="scss" scoped>
footer {
  background: #FFE577;
  padding: 55px 0;
  margin-top: 160px;

  .footer-inner {
    .footer-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;

      img {
        width: 245px;
        object-fit: contain;
      }

      div {
        display: flex;
        gap: 15px;

        a {
          width: 27px;
          height: 27px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #353533;

          i {
            font-size: 15px;
            color: #FFE577;
          }
        }
      }
    }

    .footer-bottom {
      display: flex;
      justify-content: space-between;

      div {
        display: flex;
        gap: 40px;

        a {
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          color: #353533;

        }
      }

      b {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        color: #353533;
      }
    }
  }
}
@media only screen and (max-width: 1450px) {
  footer {
    padding: 55px 0;
    margin-top: 100px;

    .footer-inner {
      .footer-top {
        margin-bottom: 35px;

        img {
          width: 200px;
        }

        div {
          gap: 15px;

          a {
            width: 25px;
            height: 25px;
            i {
              font-size: 12px;
            }
          }
        }
      }

      .footer-bottom {
        div {
          gap: 25px;

          a {
            font-size: 14px;
            line-height: 20px;
          }
        }

        b {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}
</style>
<template>
  <div class="warnHr" :class="{active:isActive,'pay-complete':type}">
    <div class="text-warn wellowHr-container">
      <div class="icon-text ">
        <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
        <p>{{ message }}</p>
      </div>
      <div class="times">
        <button @click="closeWarn"><i class="fa-regular fa-xmark"></i></button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props:['type','message'],
  data(){
    return{
      isActive:true
    }
  },
  methods:{
    closeWarn(){
      this.isActive=false
    }
  }
}
</script>
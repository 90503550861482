import {appAxios} from '../../appAxios.js'
import store from '@/store'

const user = store.getters._getCurrentUser

let userFavoriteProducts
let userFavoriteExperiences
if(user?.roles[0]?.id === 4){
    userFavoriteProducts = appAxios.post("user/favorite", {
        token: user.vue_token
    })
        .then(response => {
            let products = response.data.data.products
            products.forEach(p =>{
                p.cardType = 1
                p.isFavorite = 1
            })
            return products
        })

    userFavoriteExperiences = appAxios.post("user/favorite", {
        token: user.vue_token
    })
        .then(response => {
            let experiences = response.data.data.experiences
            experiences.forEach(element => {
                if(element){
                    element.created_at = new Date(element.created_at)
                    element?.get_sessions.forEach(exs => {
                        exs.date = exs.date.split('-')
                        exs.date = new Date(exs.date[0], exs.date[1] - 1, exs.date[2])
                    })
                    element.cardType = 0
                    element.isFavorite = 1
                }

            })
            return experiences
        })
}

const User = {
    getUserFavoriteProducts() {
        return userFavoriteProducts
    },
    getUserFavoriteExperiences() {
        return userFavoriteExperiences
    }
}

export default User
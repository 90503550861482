<template>
  <div class="wellow-modal-shadow">
    <div class="wellow-modal new-account-modal" id="new-account-flow">
      <i class="fa-thin fa-xmark close-modal" @click="closeModals"></i>
      <div class="wellow-modal-inner">
        <div class="wellow-modal-head life-circle-head">
          <h2>Yaşam Çemberini Oluştur</h2>
        </div>
        <div class="wellow-modal-body life-circle-body">
          <p>Yaşam Çemberi hayatınızda doyum eksikliği yaşadığınız alanları belirlememiz için bize yol gösterir. Aşağıda
            8
            farklı kategoride kendinizi nerede gördüğünüzü puanlayın. Bu sayede size daha uygun etkinlikler, deneyimler,
            ürünler sunabiliriz. </p>
          <div class="lif-circle-body-inner">
            <div class="life-circle-slider">
              <button class="left-button" @click="swiper.slidePrev()"><i class="fa-light fa-chevron-left"></i></button>
              <swiper :modules="modules" :allowTouchMove="false" :options="swiperOptions" @swiper="onSwiper">
                <swiper-slide>
                  <LifeCircleStep color="#FC3015" :rate="datas[0]" index="0" @changeValue="changeValue">
                      <div class="step-title"><h2>1/8</h2>
                          <h3>Verimlilik & Performans</h3></div>
                      <p class="step-desc">
                          Günlük ve iş hayatında zamanını verimli bir şekilde kullandığını düşünüyor musun? Sorumluluklarına harcadığın zamanın karşılığını bulduğunu düşünüyor musun?
                      </p>
                  </LifeCircleStep>
                </swiper-slide>
                <swiper-slide>
                  <LifeCircleStep color="#FCB9DC" :rate="datas[1]" index="1" @changeValue="changeValue">
                      <div class="step-title"><h2>3/8</h2>
                          <h3>Sağlık & Zindelik</h3></div>
                      <p class="step-desc">
                          Fiziksel olarak nasıl hissediyorsun? Genel anlamda zindelik durumun nasıl? Görünüşünden ve kilondan memnun musun?
                      </p>
                  </LifeCircleStep>
                </swiper-slide>
                <swiper-slide>
                  <LifeCircleStep color="#FE9800" :rate="datas[2]" index="2" @changeValue="changeValue">
                      <div class="step-title"><h2>4/8</h2>
                          <h3>Aşk & İlişkiler</h3></div>
                      <p class="step-desc">
                          Romantik anlamda mutlu musun? Seni tatmin eden bir ilişkin var mı? Ya da ilişkin yoksa bu durumdan memnun musun?
                      </p>
                  </LifeCircleStep>
                </swiper-slide>
                <swiper-slide>
                  <LifeCircleStep color="#09BB7F" :rate="datas[3]" index="3" @changeValue="changeValue">
                      <div class="step-title"><h2>5/8</h2>
                          <h3>Kariyer & İş Hayatı</h3></div>
                      <p class="step-desc">
                          Maddi ve manevi olarak işin seni ne kadar mutlu ve tatmin ediyor? (Hayal ettiğin işte mi çalışıyorsun? Başka bir kariyer hayalin var mı?)
                      </p>
                  </LifeCircleStep>
                </swiper-slide>
                <swiper-slide>
                  <LifeCircleStep color="#D1F3FF" :rate="datas[4]" index="4" @changeValue="changeValue">
                      <div class="step-title"><h2>6/8</h2>
                          <h3>Para & Yaşam Tarzı</h3></div>
                      <p class="step-desc">
                          Gelirlerin, temel ihtiyaçlarını ve yaşam tarzına bağlı olarak tercih ettiğin ürünleri, hizmetleri ve eylemleri karşılamaya yetiyor mu?
                      </p>
                  </LifeCircleStep>
                </swiper-slide>
                <swiper-slide>
                  <LifeCircleStep color="#1E48FF" :rate="datas[5]" index="5" @changeValue="changeValue">
                      <div class="step-title"><h2>7/8</h2>
                          <h3>Çevresel Etki</h3></div>
                      <p class="step-desc">
                          Çevreye olan etkin ve sürdürülebilirlik konusunda ne kadar farkındasın? Geri dönüşüm, atık ayrıştırma, su ve elektrik tüketimi, karbon ayak izi ve benzeri konularda eyleme geçiyor musun?
                      </p>
                  </LifeCircleStep>
                </swiper-slide>
                <swiper-slide>
                  <LifeCircleStep color="#090460" :rate="datas[6]" index="6" @changeValue="changeValue">
                      <div class="step-title"><h2>8/8</h2>
                          <h3>Eğlence & Keyif</h3></div>
                      <p class="step-desc">
                          Keyif aldığın etkinlikler için hayatında ne kadar zaman ayırıyorsun? (Seyahat, kültür ve sanat etkinlikleri, spor, hobiler, vs.)
                      </p>
                  </LifeCircleStep>
                </swiper-slide>
                <swiper-slide>
                  <LifeCircleStep color="#FFE577" :rate="datas[7]" index="7" @changeValue="changeValue">
                    <div class="step-title"><h2>8/8</h2>
                      <h3>Verimlilik & Performans</h3></div>
                    <p class="step-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et
                      dolore magna aliqua.</p>
                  </LifeCircleStep>
                </swiper-slide>
              </swiper>
              <button class="right-button" @click="swiper.slideNext()"><i class="fa-light fa-chevron-right"></i>
              </button>
            </div>
            <div class="life-circle-graph">
              <LifeCircleChart :datas="datas"/>
            </div>
          </div>
        </div>
        <div class="wellow-modal-foot">
          <div class="buttons">
            <button class="prev" @click="closeModals">{{ $t("cancel") }}</button>
            <button class="next" @click="postWheel">{{ $t("save") }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LifeCircleStep from "@/components/modals/newAccountSteps/lifeCircleStep.vue";
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import {Navigation} from 'swiper';
import LifeCircleChart from "@/components/modals/newAccountSteps/life-circle-chart.vue";

export default {
  components: {
    LifeCircleChart,
    LifeCircleStep,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: '.specialForYou-right-arrow',
          prevEl: '.specialForYou-left-arrow'
        },
      },
      swiper: null,
      datas: [],
      apiWheel: null,
    }
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
  methods: {
    changeValue(value, index) {
      this.datas[index] = value * .5 + 5;
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    closeModals() {
      this.$emit("closeModal")
    },
    handleSlideTo() {
      this;
    },
    getDatas() {
      if (this.apiWheel?.career_businessLife) {
        this.datas[0] = this.apiWheel?.career_businessLife * .5 + 5
        this.datas[1] = this.apiWheel?.efficiency_performance * .5 + 5
        this.datas[2] = this.apiWheel?.fun_joy * .5 + 5
        this.datas[3] = this.apiWheel?.health_wellness * .5 + 5
        this.datas[4] = this.apiWheel?.leadership_influence * .5 + 5
        this.datas[5] = this.apiWheel?.love_relations * .5 + 5
        this.datas[6] = this.apiWheel?.mind_meaning * .5 + 5
        this.datas[7] = this.apiWheel?.money_lifeStyle * .5 + 5
      }
    },
    reverseValue(val) {
      if (val === 0) {
        return 0
      } else {
        return val * .5 + 5
      }
    },
    postWheel() {
      this.$appAxios.post("life-wheels/update", {
        token: this.$store.getters._getToken,
        career_businessLife: (this.datas[0] - 5) * 2,
        efficiency_performance: (this.datas[1] - 5) * 2,
        fun_joy: (this.datas[2] - 5) * 2,
        health_wellness: (this.datas[3] - 5) * 2,
        leadership_influence: (this.datas[4] - 5) * 2,
        love_relations: (this.datas[5] - 5) * 2,
        mind_meaning: (this.datas[6] - 5) * 2,
        money_lifeStyle: (this.datas[7] - 5) * 2,
      })
          .then(response => {
            if (response.status === 200) {
              location.reload()
            }
          })
    }
  },
  async created() {
    this.apiWheel = await this.$LifeWheelAPI.getLifeWheels()
    this.getDatas()
  },
}
</script>
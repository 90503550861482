<template>
  <div class="icon-button" :class="{active:langActive}" v-click-outside-element="removeActive">
    <div class="language" @click="langActive=!langActive"><i
        class="fa-regular fa-globe"></i>{{ this.$store.getters._getLang.toUpperCase() }}
    </div>
    <div class="accountMenu languageMenu">
      <div @click="changeLang('tr')"><span>Türkçe</span></div>
      <div @click="changeLang('en')"><span>English</span></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      langActive: false,
    };
  },
  methods: {
    removeActive() {
      this.langActive = false
    },
    changeLang(lang) {
      let beforeLang = '/'+this.$store.getters._getLang
      this.$store.commit("setLang", lang)

      let path = this.$route.path;
      const regexp = new RegExp(beforeLang, "ig")

      window.location.href= path.replace(regexp, '/'+lang)
    }
  }
}
</script>
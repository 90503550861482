<template>
  <input class="search-input" type="text" placeholder="örnek; Zumba, Disney" v-model="search" @input="filter">
  <div class="search-box" v-if="filteredData.length>0">
    <div class="search-element" :key="search" v-for="search in filteredData">
      <router-link :to="{name:'UserExperience',params:{id:search?.slug_name}}" v-if="search.cardType===0">
        <h2 v-html="searchList(search?.name)"></h2>
        <div class="search-element-detail">
          <div class="search-detail-right">
            <span>{{ search?.egitmen_adi }} &bull; {{ search?.get_city?.name }}</span>
            <span v-if="!search?.location">{{ $t("offline-experience") }}</span>
            <span v-else>{{ $t("online-experience") }}</span>
          </div>
        </div>
      </router-link>
      <router-link :to="{name:'UserProduct',params:{id:search?.slug_name}}" v-if="search?.cardType===1">
        <h2 v-html="searchList(search?.name)"></h2>
        <div class="search-element-detail">
          <div class="search-detail-right">
            <span>{{ search?.get_vendor?.name }} &bull; {{ search?.period }}</span>
            <span>{{ $t("digital-product") }}</span>
          </div>
        </div>
      </router-link>
      <router-link :to="{name:'UserVendor',params:{id:search?.slug_name}}" v-if="search?.cardType===3">
        <h2 v-html="searchList(search?.name)"></h2>
        <div class="search-element-detail">
          <div class="search-detail-right">
            <span>{{ search?.location }}</span>
            <span>{{ $t("brand") }}</span>
          </div>
        </div>
      </router-link>
    </div>
  </div>
  <button class="search-button"><i class="fa-regular fa-magnifying-glass"></i></button>
</template>
<script>
export default {
  props:['type'],
  data() {
    return {
      sources:[],
      filteredData:[],
      search: "",
    }
  },
  async created() {
    if(parseInt(this.type) === 1){
      let experiencess = await this.$ExperienceAPI.getExperiences()
      experiencess.forEach(e=>{
        this.sources.push(e)
      })
    }
    else if(parseInt(this.type) === 2){
      let productss = await this.$ProductAPI.getProduct()
      productss.forEach(p=>{
        this.sources.push(p)
      })
    }
    else if(parseInt(this.type) === 3){
      let vendorss = await this.$VendorAPI.getVendors()
      vendorss.forEach(v=>{
        this.sources.push(v)
      })
    }
    else{
      let experiences = await this.$ExperienceAPI.getExperiences()
      let products = await this.$ProductAPI.getProduct()
      let vendors = await this.$VendorAPI.getVendors()
      experiences.forEach(e=>{
        this.sources.push(e)
      })
      products.forEach(p=>{
        this.sources.push(p)
      })
      vendors.forEach(v=>{
        this.sources.push(v)
      })
    }
    this.filteredData = this.sources
  },
  methods: {
    filter(){
      this.filteredData = this.sources.filter(data => data.name.toLocaleLowerCase().includes(this.search.toLocaleLowerCase()))
    },
    searchList(name) {
      const regexp = new RegExp(this.search, "ig")
      return name.replace(regexp, '<strong>$&</strong>')
    },
  }
}
</script>

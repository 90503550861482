import { appAxios } from '../../appAxios.js'
import store from '@/store'
const user = store.getters._getCurrentUser

let userExperiences
let userProducts
let companyExperiences
let companyProducts

if(user?.roles[0]?.id === 4) {
    userExperiences = appAxios.post("user/clientTransactionHistory", {
        token: user.vue_token,
    })
        .then(response => {
            let experiences = response.data.data.experienceTransactionHistory
            experiences.forEach(e=>{
                e.get_experience[0].get_sessions.forEach(exs=>{
                    exs.date = exs.date.split('-')
                    exs.date = new Date(exs.date[0], exs.date[1] - 1, exs.date[2])
                })
            })
            return experiences
        })
    companyExperiences = appAxios.post("user/clientTransactionHistory", {
        token: user.vue_token,
    })
        .then(response => {
            return response.data.data.companyExperienceTransactions
        })
    userProducts = appAxios.post("user/clientTransactionHistory", {
        token: user.vue_token
    })
        .then(response => {
            return response.data.data.productTransactionHistory
        })
    companyProducts = appAxios.post("user/clientTransactionHistory", {
        token: user.vue_token
    })
        .then(response => {
            return response.data.data.companyProductTransactions
        })
}
const UserPurchased ={

    async getUserExperiences(){
        return userExperiences
    },
    async getUserProducts(){
        return userProducts
    },
    async getCompanyExperiences(){
        return companyExperiences
    },
    async getCompanyProducts(){
        return companyProducts
    },
}

export default UserPurchased

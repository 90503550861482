<template>
    <div class="warnHr offer-notif" :class="{active:isActive}" v-if="offer">
        <div class="text-warn wellowHr-container">
            <div class="icon-text ">
                <i class="fa-sharp fa-solid fa-circle-exclamation"></i>
                <p>
                    <print-date-with-year :date="offer.created_at"/>
                    ’te fiyat almak istediğiniz
                    <a @click="goItem()" style="cursor:pointer;">
                        {{ offer.get_item.name }}
                    </a>
                    için fiyat bilgilendirmesi yapılmışıtır.
                </p>
            </div>
            <div class="times">
                <button @click="closeWarn"><i class="fa-regular fa-xmark"></i></button>
            </div>
        </div>
    </div>
</template>
<script>
import PrintDateWithYear from "@/components/share/tools/printDateWithYear.vue";

export default {
    components: {PrintDateWithYear},
    data() {
        return {
            isActive: true,
            offer: null
        }
    },
    async created() {
        let ApiOffers = await this.$CompanyOffersAPI.getCompanyOffers()
        let offers = ApiOffers.filter(o => o.onay_durumu === 1)
        offers.forEach(o => {
            if(!this.$store.state.readNotifications.includes(o.id)){
                this.offer = o
            }
        })
    },
    methods: {
        closeWarn() {
            this.$store.commit("readNotifications", this.offer.id)
            this.isActive = false
        },
        goItem(){
            this.$store.commit("readNotifications", this.offer.id)
            this.$router.push({name:'HrSpecialExperience',params:{id:this.offer.get_item.slug_name}})
        }
    }
}
</script>
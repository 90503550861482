import {appAxios} from '../../appAxios.js'
import store from '@/store'

const user = store.getters._getCurrentUser
let user_categories
let categories
if (user) {

    user_categories = appAxios.post("user/categories", {
        token: user.vue_token,
    })
        .then(response => {
            let cats = []
            response.data.data.forEach(cat => {
                cats.push(cat.category_id)
            })
            return cats
        })

    categories = appAxios.post("categories/listMainCat", {
        token: user.vue_token,
    })
        .then(response => {
            let cats = response.data.data
            user_categories.then(data => {
                cats.map(cat => cat.activity = data.includes(cat.id))
            })
            return cats
        })
}


const Category = {
    async getCategories() {
        return categories
    },
    async getUserCategories() {
        return user_categories
    },
}

export default Category

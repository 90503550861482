<template>
  {{ printDate(this.sessions[0]?.date) }} -
  {{ printDate(this.sessions[this.sessions?.length - 1]?.date) }}
</template>
<script>
export default {
  props:["sessions"],
  methods:{
    printDate(date){
      if(date){
        return date.getDate() + " " +
            date.toLocaleString('default', {month: 'long'}) + " ";
      }
    }
  }
}
</script>
import { appAxios } from '../../appAxios.js'
import store from '@/store'
const user = store.getters._getCurrentUser
let lifeWheel

if(user?.roles[0]?.id === 4) {
    lifeWheel = appAxios.post("life-wheels/userLifeWhell", {
        token: user.vue_token
    })
        .then(response => {
            return response.data.data[0]
        })
}
const LifeWheel ={
    async getLifeWheels(){
        return lifeWheel
    },
}

export default LifeWheel

import {appAxios} from '../../appAxios.js'
import store from '@/store'

const user = store.getters._getCurrentUser
let companyOffers

if (user?.roles[0]?.id === 3) {

    companyOffers = appAxios.post("hr/marketPlace/getCompanyOffers", {
        token: user.vue_token,
    })
        .then(response => {
            let offers = response.data.data
            offers.forEach(o => {
                o.created_at = new Date(o.created_at)
                o.start_date = o.start_date.split('-')
                o.due_date = o.due_date.split('-')
                o.start_date = new Date(o.start_date[0], o.start_date[1] - 1, o.start_date[2])
                o.due_date = new Date(o.due_date[0], o.due_date[1] - 1, o.due_date[2])
            })
            return offers
        })
}


const CompanyOffer = {
    async getCompanyOffers() {
        return companyOffers
    },
}

export default CompanyOffer

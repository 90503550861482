<template>
  <header id="user-header">
    <div class="header-inner wellow-container">
      <div class="header-top">
        <div class="header-top-left">
          <router-link :to="{name:'UserHome'}">
            <img src="@/assets/images/logo.svg" alt="">
          </router-link>
        </div>
        <div class="header-top-right">
          <router-link :to="{name:'UserFavorites'}" class="icon-button">
            <i class="fa-regular fa-heart"></i>
          </router-link>
          <accountMenu/>
          <languageMenu/>
        </div>
      </div>
      <div class="header-bottom">
        <div class="header-bottom-left">

          <router-link :to="{name:'UserCatalog'}" :class="{active:this.activeMenu==='KATALOG'}">
            <span>{{ $t("catalog-upper") }}</span>
          </router-link>

          <router-link :to="{name:'UserEditorSelection'}" :class="{active:this.activeMenu==='EDİTÖRÜN SEÇİMİ'}">
            <span>{{ $t("editor-choice-upper") }}</span>
          </router-link>

          <router-link :to="{name:'UserBrands'}" :class="{active:this.activeMenu==='MARKALAR'}">
            <span>{{ $t("brands-upper") }}</span>
          </router-link>

        </div>
        <div class="header-bottom-right">
          <search-area :type="0"/>
        </div>
      </div>
    </div>
  </header>
  <div class="head-space"></div>
</template>
<script>
import searchArea from './header/searchArea.vue'
import accountMenu from './header/accountMenu.vue'
import languageMenu from "@/components/share/languageMenu.vue";

export default {
  data() {
    return {
      langActive: false,
    };
  },
  methods: {
    removeActive() {
      this.langActive = false
    },
    changeLang(lang) {
      let beforeLang = '/'+this.$store.getters._getLang
      this.$store.commit("setLang", lang)

      let path = this.$route.path;
      const regexp = new RegExp(beforeLang, "ig")

      window.location.href= path.replace(regexp, '/'+lang)
    },
  },
  props: ["activeMenu"],
  components: {
    searchArea,
    accountMenu,
    languageMenu
  },
}
</script>
<style lang="scss" scoped>
  header {
    background: #FDFDFD;
    border: 1px solid #CED4DA;
    position: fixed;
    width: 100vw;
    top: 0;
    z-index: 999;

    .header-inner {
      height: max-content;
      display: flex;
      flex-direction: column;
      .header-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 75px;
        width: 100%;
        .header-top-left {
          img {
            width: 127px;
          }
        }

        .header-top-right {
          display: flex;
          align-items: center;
          gap: 30px;

          .icon-button {
            position: relative;

            i {
              color: black;
              font-size: 20px;
              width: 36px;
              height: 36px;
              font-weight: 400;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              transition: 400ms all;
              cursor: pointer;

              &:hover {
                font-weight: 800;
                border: 1px solid #CED4DA;
                box-shadow: 0 2px 10px #6C757D3F;
                color: #FEC629;;
              }
            }

            .accountMenu {
              position: absolute;
              opacity: 0;
              top: 50px;
              width: max-content;
              z-index: 88;
              background: white;
              visibility: hidden;
              transition: 400ms all;
              box-shadow: 0 2px 10px rgba(108, 117, 125, 0.25);
              border-radius: 10px;
              right: 0;
              overflow: hidden;

              div,
              a {
                padding: 15px 32px;
                border-bottom: 1px solid black;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                transition: 400ms all;
                cursor: pointer;

                span {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  display: flex;
                  align-items: center;
                  color: #3A3A3A;
                }

                &:hover {
                  background: #FEF5CC;
                }

                &:last-child {
                  border: none;
                }
              }

            }

            &.active {
              .accountMenu {
                opacity: 1;
                visibility: visible;
              }

              i {
                font-size: 20px;
                width: 36px;
                font-weight: 400;
                border: 1px solid #CED4DA;
                box-shadow: 0 2px 10px #6C757D3F;
                color: #FEC629;
              }
            }
          }

          .icon-button {

            .language {
              font-size: 14px;
              display: flex;
              align-items: center;

              &:hover{
                font-weight: 800;
                border: 1px solid #CED4DA;
                box-shadow: 0 2px 10px #6C757D3F;
                color: #FEC629;;
              }
            }

            .languageMenu {
              width: max-content;
              border: 1px solid #CED4DA;

              div {
                padding: 15px 32px;
                gap: 10px;
              }
            }

          }
        }
      }

      .header-bottom {
        display: flex;
        justify-content: space-between;
        height: 65px;
        align-items: center;
        width: 100%;

        .header-bottom-left {
          display: flex;
          gap: 40px;

          a {
            color: black;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 16px;
            text-transform: uppercase;
            line-height: 24px;
            position: relative;

            &:before {
              position: absolute;
              content: "";
              width: calc(100% + 10px);
              background: #FEC629;
              height: 3px;
              bottom: -5px;
              opacity: 0;
              visibility: hidden;
              transition: 400ms all;
              border-radius: 10px;
            }

            &.active {
              &:before {
                opacity: 1;
                visibility: visible;
              }
            }

            &:hover {
              &:before {
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }

        .header-bottom-right {

          display: flex;
          align-items: center;
          padding: 0;
          height: 40px;
          width: 400px;
          position: relative;
          justify-content: flex-end;

        }
      }
    }
  }
  .head-space {
    height: 142px;
  }
  @media only screen and ( max-width: 1450px) {
    header {
      .header-inner {
        .header-top {
          height: 50px;
          .header-top-left {
            a{
              display: flex;
              align-items: center;
              justify-content: center;
            }
            img {
              width: 100px;
            }
          }

          .header-top-right {
            gap: 15px;

            .icon-button {
              i {
                font-size: 17px;
                width: 25px;
                height: 25px;
              }

              .accountMenu {
                top: 50px;

                div,
                a {
                  padding: 15px 32px;
                  span {
                    font-size: 16px;
                    line-height: 24px;
                  }
                }
              }

              &.active {
                i {
                  font-size: 17px;
                  width: 35px !important;
                  height: 35px !important;
                }
              }
            }

            .icon-button {
              .language {
                font-size: 14px;
              }

              &:hover {
                i {
                  font-size: 20px;
                  width: 36px;
                }
              }
            }
          }
        }

        .header-bottom {
          height: 55px;

          .header-bottom-left {
            gap: 40px;

            a {
              font-size: 14px;
            }
          }

          .header-bottom-right {
            height: 35px;
            width: 300px;
            .search-input {
              font-size: 10px;
              line-height: 10px;
            }
            .search-button i {
              font-size: 14px;
            }
          }
        }
      }
    }
    .head-space {
      height: 107px;
    }
  }

</style>

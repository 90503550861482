<template>
  <offer-notif />
<!--  <hr-warn :type="false" :message="message1"/>-->
<!--  <hr-warn :type="true" :message="message2"/>-->
</template>
<script>
import HrWarn from "@/components/share/hr/hrWarn.vue";
import OfferNotif from "@/components/share/hr/notifications/offerNotif.vue";

export default {
  components: {
    OfferNotif,
    HrWarn,
  },
  async created() {
  },
  data() {
    return {
      offers:null,
      message1: "Henüz ödeme Yapmadınız. Çalışanlarınıza ancak ödeme yapıldıktan sonra yan haklar sunabilir ve/veya\n" +
          " çalışanlarınızın Wellow platformuna ulaşmasını saglayabilirsiniz.",
      message2: "Ödemeniz Tamamlandı!",
    }
  }
};
</script>
import { appAxios } from '../../appAxios.js'
import store from '@/store'
import User from "@/utils/requests/user/user";
import UserPurchased from "@/utils/requests/user/userPurchased";
const user = store.getters._getCurrentUser
let products
if(user?.roles[0]?.id === 4) {
    products = appAxios.post("product/list", {
        token: user.vue_token,
        type: 0
    })
        .then(response => {
            let products = response.data.data

            products.forEach(p => {
                p.cardType = 1
                User.getUserFavoriteProducts().then(response=>{
                    response.forEach(exs => {
                        if(exs.id === p.id ){
                            p.isFavorite = 1
                        }
                    })
                })
                UserPurchased.getUserProducts().then(response=>{
                    response.forEach(exs => {
                        if(exs.foreign_id === p.id ){
                            p.isBought = 1
                        }
                    })
                })
                p.created_at = new Date(p.created_at)
                let lifeTypes = []
                p.get_product_benefits_middle.forEach(pbm=>{
                    if(!(lifeTypes.lastIndexOf(pbm.get_life_type.slug_name) > -1)){
                        lifeTypes.push(pbm.get_life_type.slug_name)
                    }
                })
                p.life_types = lifeTypes
            })
            return products
        })
}

const Product ={
    async getProduct(){
        return products
    },
    boughtProduct(id){
        products.then(pro=>{
            pro.forEach(p=>{
                if(id === p.id){
                    p.isBought = 1
                }
            })
        })
    },
    addFavoriteProduct(id){
        products.then(pro=>{
            pro.forEach(p=>{
                if(id === p.id){
                    p.isFavorite = 1
                }
            })
        })
    },
    removeFavoriteProduct(id){
        products.then(pro=>{
            pro.forEach(p=>{
                if(id === p.id){
                    p.isFavorite = 0
                }
            })
        })
    }
}

export default Product

<template>
  <div id="chart">
    <apexcharts type="polarArea" width="350" :options="chartOptions" :series="series"></apexcharts>
    <div class="chart-circle"><span>{{ this.$t("circle-of-life") }}</span>
      <div class="chart-values">
        <span v-for="d in datas" :key="d" :style="{'--circleVal':(d-5)*2}">{{ (d - 5) * 2 }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import apexcharts from "vue3-apexcharts";

export default {
  components: {
    apexcharts,
  },
  props: ["datas", "isScale"],
  watch: {
    datas() {
      this.series = this.datas
    }
  },
  data() {
    return {
      //5.0 =>0
      //5.5 =>1
      //6.0 =>2
      //6.5 =>3
      //7.0 =>4
      //7.5 =>5
      //8.0 =>6
      //8.5 =>7
      //9.0 =>8
      //9.5 =>9
      //10.0 =>10
      series: this.datas,
      chartOptions: {
        labels: ['Rose A', 'Rose B', 'Rose C', 'Rose D', 'Rose E', 'Rose E', 'Rose E', 'Rose E'],
        fill: {
          opacity: 1
        },
        stroke: {
          width: 1,
          colors: undefined
        },
        colors: ['#FC3015', '#FCB9DC', '#FE9800', '#09BB7F', '#D1F3FF', '#1E48FF', '#090460', '#FFE577'],
        yaxis: false,
        legend: false,
        plotOptions: {
          polarArea: {
            rings: false,
            spokes: {
              strokeWidth: 0
            },
          }
        },
      },
    }
  },

}
</script>
import {appAxios} from '../../appAxios.js'
import store from '@/store'

const user = store.getters._getCurrentUser
let sustainabilityGoals

if (user?.roles[0]?.id === 3) {

    sustainabilityGoals = appAxios.post("hr/sustainabilityGoals/list", {
        token: user.vue_token,
    })
        .then(response => {
            return response.data.data
        })
}


const SustainabilityGoal = {
    async getSustainabilityGoals() {
        return sustainabilityGoals
    },
}

export default SustainabilityGoal

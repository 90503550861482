import { appAxios } from '../../appAxios.js'
import store from '@/store'
const user = store.getters._getCurrentUser
let companyItems

if(user?.roles[0]?.id === 3){

    companyItems = appAxios.post("hr/client/getCompanyPurchasedItems", {
        token: user.vue_token,
    })
        .then(response => {
            let items = response.data.data.items
            items.forEach(i=>{
                if(i?.get_sessions){
                        i.type = 0;
                        i.get_sessions.forEach(exs => {
                            exs.date = exs.date.split('-')
                            exs.date = new Date(exs.date[0], exs.date[1] - 1, exs.date[2])
                        })
                }
                else{
                    i.type=1
                }
            })
            return response.data.data.items
        })
        .catch(error=>{
        })
}



const CompanyItem ={
    async getCompanyItems(){
        return companyItems
    },
}

export default CompanyItem

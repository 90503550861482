<template>
  <div class="life-circle-step">
    <slot>

    </slot>
    <div class="life-circle-rank" :class="lifeCircleRate" :style="{'--backColor':color}">
      <span v-for="r in rates" :key="r" @mouseover="lifeCircleRate='rate'+r" @mouseleave="lifeCircleRate=defaultClass"
            @click="changeValue(r)">{{ r }}</span>
    </div>
    <a class="not-intersted" @click="changeValue(0)">{{ this.$t("this-cat-not-important") }}</a>
  </div>
</template>
<script>
export default {
  data() {
    return {
      defaultClass: "rate0",
      lifeCircleRate: "rate0",
      rates: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    };
  },
  methods: {
    changeValue(val) {
      this.lifeCircleRate = 'rate' + val;
      this.defaultClass = 'rate' + val;
      this.$emit("changeValue", val, this.index)
    }
  },
  watch: {
    rate() {
      this.lifeCircleRate = "rate" + (this.rate - 5) * 2
      this.defaultClass = "rate" + (this.rate - 5) * 2
    }
  },
  props: ["color", "index", "rate"]
}
</script>

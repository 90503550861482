<template>
    <HrHeader :activeMenu="this.activeMenu"/>
    <hr-notifications/>
    <main class="hr-layout">
        <slot>

        </slot>
    </main>
</template>

<script>
import HrHeader from "@/components/share/hr/hrHeader.vue";
import HrNotifications from "@/components/share/hr/hrNotifications.vue";
import store from "@/store";
import appAxios from "@/utils/appAxios";

export default {
    props: ['activeMenu'],
    components: {
        HrNotifications,
        HrHeader,
    },
    created() {
        window.scrollTo(0, 0);
        if (!this.$store.getters._getApiCheck) {
            this.$store.commit("setApiCheck", true)
            location.reload()
        }
        this.$appAxios.post("/user/checkToken",
            {
                token: this.$store.getters._getToken,
            })
            .catch(res => {
                if (!res.success) {
                    this.$store.commit("logoutUser")
                    this.$router.push({name: "Login"})
                }
            })
    },
}
</script>


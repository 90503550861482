<template>
  {{ printDateWithYear }}
</template>
<script>
export default {
  props:["date"],
  computed:{
    printDateWithYear(){
      if(this.date){
        return this.date.getDate() + " " +
            this.date.toLocaleString('default', {month: 'long'}) + " " +
            this.date.getFullYear() + " ";
      }
      return ""
    }
  }
}
</script>
import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store";

const routes = [
    {
        name: "HomeEn",
        path: "/",
        params:{lang: "tr"},
        component: () => import("../views/landing/homeLanding.vue"),
    },
    //LANDİNG ROUTES
    {
        name: "Home",
        path: "/:lang",
        component: () => import("../views/landing/homeLanding.vue"),
    },
    {
        name: "Employee",
        path: "/:lang/employee",
        component: () => import("../views/landing/employeePage.vue"),
    },
    {
        name: "Employer",
        path: "/:lang/employer",
        component: () => import("../views/landing/employerPage.vue"),
    },
    {
        name: "Solutions",
        path: "/:lang/solutions/:id",
        component: () => import("../views/landing/solutionsPage.vue"),
        props: true,

    },
    {
        name: "AboutUs",
        path: "/:lang/about-us",
        component: () => import("../views/landing/aboutUsPage.vue"),

    },
    {
        name: "Login",
        path: "/:lang/login",
        component: () => import("../views/loginPage.vue"),
        meta: {
            requiresGuest: true
        },
    },


    //USER ROUTES
    {
        name: "UserHome",
        path: "/:lang/user",
        component: () => import("../views/user/homePage.vue"),
        meta: {
            requiresUserAuth: true
        },
    },
    {
        name: "UserCatalog",
        path: "/:lang/user/catalog",
        component: () => import("../views/user/catalogPage.vue"),
        meta: {
            requiresUserAuth: true
        },
        props: true,
    },
    {
        name: "UserFavorites",
        path: "/:lang/user/favorites",
        component: () => import("../views/user/favoritesPage.vue"),
        meta: {
            requiresUserAuth: true
        },
    },
    {
        name: "UserFAQ",
        path: "/:lang/user/faq",
        component: () => import("../views/user/faqPage.vue"),
        meta: {
            requiresUserAuth: true
        },
    },

    //COMMON ROUTES
    {
        name: "UserBrands",
        path: "/:lang/user/brands",
        component: () => import("../views/user/vendorsPage.vue"),
        meta: {
            requiresUserAuth: true
        },
    },
    {
        name: "UserEditorSelection",
        path: "/:lang/user/editor-selection",
        component: () => import("../views/user/editorSelectionsPage.vue"),
        meta: {
            requiresUserAuth: true
        },
    },
    {
        name: "UserExperience",
        path: "/:lang/user/experience/:id",
        component: () => import("../views/user/innerPages/experienceDetail.vue"),
        meta: {
            requiresUserAuth: true
        },
        props: true,
    },
    {
        name: "UserProduct",
        path: "/:lang/user/product/:id",
        component: () => import("../views/user/innerPages/productDetail.vue"),
        meta: {
            requiresUserAuth: true
        },
        props: true,
    },
    {
        name: "UserVendor",
        path: "/:lang/user/vendor/:id",
        component: () => import("../views/user/innerPages/vendorDetail.vue"),
        meta: {
            requiresUserAuth: true
        },
        props: true,
    },
    {
        name:"UserLifeType",
        path:"/:lang/user/life-type/:id",
        component: () => import("../views/user/innerPages/GoodLifeDetail.vue"),
        meta: {
            requiresUserAuth: true
        },
        props:true,
    },
    {
        name:"UserPurchased",
        path:"/:lang/user/purchased",
        component: () => import("../views/user/purchasedPage.vue"),
        meta: {
            requiresUserAuth: true
        },
        props:true,
    },
    {
        name:"UserEditorGroupDetail",
        path:"/:lang/user/editor-group/:id",
        component: () => import("../views/user/innerPages/editorGroupDetail.vue"),
        meta: {
            requiresUserAuth: true
        },
        props:true,
    },
    //HR ROUTES
    {
        name: "HrHome",
        path: "/:lang/hr",
        component: () => import("../views/hr/hrPlaning.vue"),
        meta: {
            requiresHrAuth: true
        },
    },
    {
        name: "HrTeamManagement",
        path: "/:lang/hr/team-management",
        component: () => import("../views/hr/hrTeamManagement.vue"),
        meta: {
            requiresHrAuth: true
        },
    },
    {
        name: "HrEditorChoice",
        path: "/:lang/hr/market-place/editor-choice",
        component: () => import("../views/hr/hrEditorChoice.vue"),
        meta: {
            requiresHrAuth: true
        },
    },
    {
        name: "HrEmployee",
        path: "/:lang/hr/market-place/employee/:userID?",
        component: () => import("../views/hr/hrEmployee.vue"),
        meta: {
            requiresHrAuth: true
        },
        props:true,
    },
    {
        name: "HrExperience",
        path: "/:lang/hr/market-place/employee/experience/:id:/:userID?",
        component: () => import("../views/hr/innerPages/hrExperience.vue"),
        meta: {
            requiresHrAuth: true
        },
        props:true,
    },
    {
        name: "HrProduct",
        path: "/:lang/hr/market-place/employee/product/:id/:userID?",
        component: () => import("../views/hr/innerPages/hrProduct.vue"),
        meta: {
            requiresHrAuth: true
        },
        props:true,
    },
    {
        name: "HrEditorChoiceInner",
        path: "/:lang/hr/editor-choice/:id",
        component: () => import("../views/hr/innerPages/hrEditorInner.vue"),
        meta: {
            requiresHrAuth: true
        },
        props:true,
    },
    {
        name: "HrSpecialItems",
        path: "/:lang/hr/market-place/special-items/:cat_id",
        component: () => import("../views/hr/hrSpecialItemsPage.vue"),
        meta: {
            requiresHrAuth: true
        },
        props:true,
    },
    {
        name: "HrSpecialExperience",
        path: "/:lang/hr/market-place/special-items/experience/:id",
        component: () => import("../views/hr/innerPages/hrSpecialExperience.vue"),
        meta: {
            requiresHrAuth: true
        },
        props:true,
    },
    {
        name: "HrSpecialProduct",
        path: "/:lang/hr/market-place/special-items/product/:id",
        component: () => import("../views/hr/innerPages/hrSpecialProduct.vue"),
        meta: {
            requiresHrAuth: true
        },
        props:true,
    },

    { path: '/:pathMatch(.*)*', redirect: '/' }
]
const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    store.dispatch('checkTokenValidity')
    if(to.params.lang !=="en" && to.params.lang !=="tr"){
        next({name:'Home',params:{lang:navigator.language.slice(0, 2)}});
        return
    }
    const requiresUserAuth = to.matched.some((x) => x.meta.requiresUserAuth);
    const requiresHrAuth = to.matched.some((x) => x.meta.requiresHrAuth);
    const requiresGuest = to.matched.some((x) => x.meta.requiresGuest);
    const user = store.getters["_getCurrentUser"];
    if(!user && (requiresUserAuth || requiresHrAuth)){
        next({name: "Login",params:{lang:to.params.lang}});
    }
    else if(user?.roles[0]?.id === 4 && (requiresGuest || requiresHrAuth)){
        next({name:'UserHome',params:{lang:to.params.lang}});
    }
    else if(user?.roles[0]?.id === 3 && (requiresGuest || requiresUserAuth)){
        next({name:'HrHome',params:{lang:to.params.lang}});
    }else{
        next()
    }
});
export default router;
<template>
    <header>
        <div class="header-inner wellowHr-container">
            <div class="header-left">
                <router-link :to="{name:'HrHome'}">
                    <img src="@/assets/images/logo.svg" alt="">
                </router-link>
                <div class="text-menu" :class="{active:activeMenu==='EKİP YÖNETİMİ'}">
                    <router-link :to="{name:'HrTeamManagement'}">
                      <span>
                        EKİP YÖNETİMİ
                      </span>
                    </router-link>
                </div>
                <div class="text-menu" :class="{active:activeMenu==='PLANLAMA'}">
                    <router-link :to="{name:'HrHome'}">
                      <span>
                        PLANLAMA
                      </span>
                    </router-link>
                </div>
                <div class="text-menu" :class="{active:activeMenu==='PAZAR YERİ'}"
                     v-click-outside-element="removeActive">
                    <a @click="marketMenu=!marketMenu">
                      <span>
                        PAZAR YERİ
                      </span>
                    </a>
                    <div class="market-menu" :class="{active:marketMenu}">
                        <nav class="wellowHr-container">
                            <ul>
                                <li>
                                    <a>Çalışan</a>
                                    <ul>
                                        <li>
                                            <router-link :to="{name:'HrEmployee'}">Ödül ve Yan Haklar</router-link>
                                            <i class="fa-sharp fa-light fa-angle-right"></i>
                                        </li>
                                    </ul>
                                </li>
                                <market-menu-li :cats="categories"/>
                                <li>
                                    <router-link :to="{name:'HrEditorChoice'}">Editörden Seçkiler</router-link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div class="text-menu reports-menu-link" :class="{active:activeMenu==='RAPORLAR'}">
                    <a>
                      <span>
                        RAPORLAR
                      </span>
                    </a>
                    <ul class="reports-menu">
                        <li>
                            <a href="/sustainability_report.pdf" download>Sürdürülebilirlik Raporu</a>
                            <i class="fa-sharp fa-light fa-angle-right"></i>
                        </li>
                        <li>
                            <a href="/employee.report.pdf" download>Çalışan Raporu</a>
                            <i class="fa-sharp fa-light fa-angle-right"></i>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="header-right">
                <div class="icon-button">
                    <a>
                        <div class="notification">
                            <i class="fa-regular fa-bell"></i>
                        </div>
                    </a>

                </div>
                <div class="icon-button" :class="{active:isActive}" v-click-outside-element="closeMenu">
                    <i class="fa-regular fa-user" @click="isActive=!isActive"></i>
                    <div class="accountMenu">
                        <div @click="logout"><span>{{ $t("logout") }}</span></div>
                    </div>
                </div>
                <languageMenu/>
            </div>
        </div>
    </header>
    <div class="hr-head-space"></div>
</template>
<script>
import languageMenu from "@/components/share/languageMenu.vue";
import hrMarketCategory from "@/utils/requests/hr/hrMarketCategory";
import MarketMenuLi from "@/components/share/hr/marketMenuLi.vue";

export default {
    props: ["activeMenu"],
    data() {
        return {
            isActive: false,
            marketMenu: false,
            categories: []
        }
    },
    async created() {
        this.categories = await hrMarketCategory.getHrCategories()
    },
    components: {
        MarketMenuLi,
        languageMenu
    },
    methods: {
        removeActive() {
            this.marketMenu = false
        },
        logout() {
            this.$store.commit("logoutUser");
            this.$router.push({name: "Login"})
        },
        closeMenu() {
            this.isActive = false
        }
    }
}
</script>
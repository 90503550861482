import {appAxios} from '../../appAxios.js'
import store from '@/store'

const user = store.getters._getCurrentUser
let hrCategories

if (user?.roles[0]?.id === 3) {

    hrCategories = appAxios.post("hr/marketPlace/categories/listMainCat", {
        token: user.vue_token,
    })
        .then(response => {
            return response.data.data
        })
}


const HrCategories = {
    async getHrCategories() {
        return hrCategories
    },
}

export default HrCategories

import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

let ls = new SecureLS({ isCompression: false });

export default createStore({
    state:{
        user: null,
        token:null,
        tokenExpirationDate:null,
        firstLogin:null,
        lang:'en',
        apiCheck:false,
        userBalance:null,
        company:null,
        readNotifications:[]
    },
    mutations:{
        setUser(state, data){
            state.user = data;
        },
        changeFirstLogin(state,boolAttr){
            state.firstLogin = boolAttr;
        },
        setToken(state,vue_token){
            state.token = vue_token;
            state.tokenExpirationDate = new Date(Date.now() + 12 * 60 * 60 * 1000);
        },
        readNotifications(state,notifID){
            state.readNotifications.push(notifID)
        },
        clearReadNotifications(state){
            state.readNotifications=[]
        },
        logoutUser(state) {
            state.user = null;
            state.token = null;
            state.apiCheck = false;
            state.readNotifications=[]
        },
        setLang(state,lang){
            state.lang = lang
        },
        setApiCheck(state,apiCheck){
            state.apiCheck = apiCheck
        },
        setBalance(state,balance){
            state.balance = balance
        },
        setCompany(state,company){
            state.company = company
        }
    },
    getters:{
        _isAuthenticated: state => state.token !== null,
        _getCurrentUser(state) {
          const user = state.user;
          delete user?.password;
          return user;
        },
        _getToken: state =>state?.token,
        _getUserEmail: state =>state?.user.email,
        _getFirstLogin: state =>state?.firstLogin,
        _currentUserId: state => state?.user?.id,
        _getLang: state => state.lang,
        _getApiCheck: state => state.apiCheck,
        _saltKey: state => state.saltKey,
        _getBalance: state => state.balance,
        _getCompany: state => state.company,
    },
    actions: {
        checkTokenValidity({ commit }) {
            const token = this.state.token
            const tokenExpirationDate = this.state.tokenExpirationDate

            if (!token || tokenExpirationDate < new Date()) {
                commit('logoutUser')
            }
        }
    },
    plugins: [
        createPersistedState({
          storage: {
            getItem: key => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: key => ls.remove(key)
          }
        })
      ],
})
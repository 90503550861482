import { appAxios } from '../../appAxios.js'
import store from '@/store'
const user = store.getters._getCurrentUser
let company

if(user?.roles[0]?.id === 3){

    company = appAxios.post("hr/client/getCompanyClients", {
        token: user.vue_token,
    })
        .then(response => {
            let res = response.data.data.clients
            res.forEach(data=>{data.is_disabled = false})
            return res
        })
}



const Company ={
    async getCompanyUsers(){
        return company
    },
}

export default Company

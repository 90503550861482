import { createApp } from 'vue';
import { createI18n } from 'vue-i18n'
import vueClickOutsideElement from 'vue-click-outside-element'
import { Icon } from '@iconify/vue';

import router from '@/router'
import store from '@/store'
import { appAxios } from '@/utils/appAxios.js'
import { languages } from '@/plugins/languages';
import App from '@/App';
import VueSweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';
import "@/assets/font-awesome/css/all.css";

import '@/assets/css/user/style.css';
import '@/assets/css/hr/style.css';
import '@/assets/css/main.css';


import ProductAPI from "@/utils/requests/user/product.js";
import ExperienceAPI from "@/utils/requests/user/experience.js";
import VendorAPI from "@/utils/requests/user/vendor.js";
import CategoryAPI from "@/utils/requests/user/category.js";
import LifeWheelAPI from "@/utils/requests/user/lifeWheel.js";

import CompanyAPI from "@/utils/requests/hr/company.js";
import CompanyItemsAPI from "@/utils/requests/hr/companyItems.js";
import SustainabilityGoalsAPI from "@/utils/requests/hr/sustainabilityGoal.js";
import CompanyOffersAPI from "@/utils/requests/hr/companyOffers";


import userLayout from "@/views/user/layout/userLayout.vue";
import hrLayout from "@/views/hr/layouts/hrLayout.vue";
import printDateWithYear from "@/components/share/tools/printDateWithYear.vue";
import printDate from "@/components/share/tools/printDate.vue";
import printSessionsDate from "@/components/share/tools/printSessionsDate.vue";

const i18n = createI18n({
    locale: store.getters._getLang,
    fallbackLocale: 'en',
    messages:languages,
})

const apiURL="https://api.wellow.io";
//const apiURL="http://127.0.0.1:8000";
const app = createApp(App)

app.component("IconFy", Icon);
app.component("UserLayout", userLayout);
app.component("HrLayout", hrLayout);
app.component("PrintDateWithYear", printDateWithYear);
app.component("PrintDate", printDate);
app.component("PrintSessionsDate", printSessionsDate);

app.use(vueClickOutsideElement)
app.use(i18n)
app.use(VueSweetalert2);
app.use(store)
app.use(router);

app.config.globalProperties.$appAxios = appAxios;

app.config.globalProperties.$ProductAPI = ProductAPI;
app.config.globalProperties.$ExperienceAPI = ExperienceAPI;
app.config.globalProperties.$VendorAPI = VendorAPI;
app.config.globalProperties.$CategoryAPI = CategoryAPI;
app.config.globalProperties.$LifeWheelAPI = LifeWheelAPI;

app.config.globalProperties.$CompanyAPI = CompanyAPI;
app.config.globalProperties.$CompanyItemsAPI = CompanyItemsAPI;
app.config.globalProperties.$SustainabilityGoalsAPI = SustainabilityGoalsAPI;
app.config.globalProperties.$CompanyOffersAPI = CompanyOffersAPI;

app.config.globalProperties.$apiURL = apiURL;

app.mount('#app')




<template>
    <li v-for="cat in cats" :key="cat">
      <router-link :to="{name:'HrSpecialItems',params:{cat_id:cat.id}}">{{cat.name}} </router-link>
      <i v-if="cat.parent_category" class="fa-sharp fa-light fa-angle-right"></i>
      <ul>
        <market-menu-li v-if="cat?.child_categories.length>0" :cats="cat?.child_categories"/>
      </ul>
    </li>
</template>

<script>

export default {
  props:["cats"]
}
</script>
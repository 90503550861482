<template>
    <div class="fixed-bar active">
        <div class="fixed-bar-inner wellow-container" :class="{active:hasMessage}">
            <div class="fixed-bar-left" v-if="hasMessage">
                <i class="fa-solid fa-bell"><span>1</span></i>
                <p>Son Deneyiminiz Nasıldı ? Değerlendirmek ister misiniz ?</p>
            </div>
            <div class="fixed-bar-right">
                <img src="@/assets/images/media/wallet.svg" alt="">
                <h2>{{ this.$store.getters._getBalance }}<span>₺</span></h2>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            hasMessage: true
        };
    },
    props: ['scY'],
}
</script>
<style lang="scss" scoped>
.fixed-bar {
  position: fixed;
  bottom: -320px;
  transition: 400ms all;
  left: 0;
  width: 100vw;
  height: 50px;
  background: rgba(254, 245, 204, 0.75);
  z-index: 55;

  .fixed-bar-inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;

    .fixed-bar-left {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;

      i {
        font-size: 18px;
        position: relative;
        color: #1E48FF;

        span {
          display: flex;
          position: absolute;
          align-items: center;
          height: 8px;
          width: 8px;
          justify-content: center;
          border-radius: 50%;
          background: white;
          font-weight: 700;
          font-size: 6px;
          line-height: 9px;
          top: 5px;
          right: -3px;
        }
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: black;
        margin: 0;
      }
    }

    .fixed-bar-right {
      font-weight: 400;
      color: white;
      padding: 8px 8px;
      display: flex;
      background: #1E48FF;
      gap: 10px;
      border-radius: 5px;
      cursor: default;

      img {
        width: 30px;
      }

      h2 {
        font-size: 18px;
        margin: 0;

        span {
          font-size: 14px;
          margin-left: 2px;
        }
      }

    }

    &.active {
      justify-content: space-between;
    }
  }

  &.active {
    bottom: 0;
  }
}

@media only screen and (max-width: 1450px) {
  .fixed-bar {
    height: 45px;

    .fixed-bar-inner {
      .fixed-bar-left {
        gap: 12px;

        i {
          font-size: 14px;
        }

        p {
          font-size: 14px;
          line-height: 20px;
        }
      }

      .fixed-bar-right {
        padding: 5px 10px;

        img {
          width: 20px;
        }

        h2 {
          font-size: 14px;

          span {
            font-size: 12px;
          }
        }
      }
    }
  }
}

</style>